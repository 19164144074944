import React from "react"
import Icon from '@mdi/react'
import SEO from "../components/seo"
import Layout from "../components/layout"
import style from '../styles/social.module.css'
import data from '../data/social.json'
import { mdiInformationVariant, mdiWeb, mdiFacebook, mdiInstagram } from '@mdi/js'

const icons = {
  "mdi mdi-information-variant" : mdiInformationVariant,
  "mdi mdi-web"                 : mdiWeb,
  "mdi mdi-facebook"            : mdiFacebook,
  "mdi mdi-instagram"           : mdiInstagram,
}

const seoData = {
  title: 'Social',
  description: 'Siguenos en redes sociales',
  path: '/social',
}


const Social = () => (
  <Layout subtitle="Síguenos en redes sociales">
    <SEO pageData={ seoData } />

    <div className={ style.container }>
        {
          data.map( ( element, i ) => {
            return ( <SocialItem key={ i } data={ element } /> )
          })
        }
    </div>
  </Layout>
)


const SocialItem = ({ data }) => {
  const icon = icons[ data.icon ];

  return (
      <a href={ data.url } 
         target="_blank" 
         rel="noopener noreferrer"
         className={ style.itemLink }>
        <div className={ style.item }>
          <div className={ style.itemIcon }>
            <Icon path={ icon } size="24px" color="white"/>
          </div>

          <p>
            <span className={ style.itemName }> { data.name } </span>
            <br />
            <span className={ style.itemDescription }> { data.description } </span>
          </p>
        </div>
      </a>
  );
};



export default Social;